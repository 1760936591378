<template>
  <div class="chosen-plan-box">
    <div class="d-flex justify-space-between">
      <h2 class="justify-space-between font-family-raleway-medium nowrapText plan-mobile" style="height: fit-content" v-if="isActive">
        <span>{{ plan.name }}</span>
        <span class="discount-badge ml-5">SAVE 15%</span>
      </h2>
      <h2 class="font-family-raleway-medium" v-else>
        {{ $t("billing_page.choose_plan_card_title") }}
      </h2>
      <div class="text-right" v-if="isActive">
        <h2>${{ plan.type === "Annual" ? plan.budget / 12 : plan.budget }}</h2>
        <p>{{ $t("create_campaign_page.billed_text") }} {{ $t(plan.type) }}</p>
      </div>
    </div>
    <div class="d-flex align-center justify-space-between mt-16 fld">
      <span class="font-raleway-regular">{{
        $t("billing_page.choose_plan_card_description")
      }}</span>
      <v-btn
        v-if="isHaveUserActive"
        class="button text-capitalize"
        height="48"
        @click="isChangePlan = true"
      >
        {{ $t("billing_page.change_plan_button") }}
      </v-btn>
      <v-btn
        v-else
        class="button text-capitalize"
        height="48"
        @click="openModalToChoosePlan"
      >
        {{ $t("billing_page.choose_plan_button") }}
      </v-btn>
    </div>
    <change-plan-modal
      :dialog="isChangePlan"
      :paymentMethodId="paymentMethodId"
      :isHaveUserActivePlan="isActive"
      :chosenPlanId="plan.id"
      :planType="plan.type"
      @handleCancel="isChangePlan = false"
      @handleUpdateDetails="getPlanDetailsAfterUpdating"
    />
  </div>
</template>

<script>
import { authService } from "@/services/auth";
import { plansService } from "@/services/plans";
import { mapGetters } from "vuex";
export default {
  name: "ChangePlanSection",
  components: {
    ChangePlanModal: () => import("@/components/dialogs/ChangePlan")
  },
  props: {
    paymentMethodId: String,
  },
  data() {
    return {
      isChangePlan: false,
      loading: false,
      isActive: false,
      plan: {},
    };
  },
  async created() {
    await this.getPlanDetails();
  },
  computed: {
    ...mapGetters(["getUser"]),
    isHaveUserActive() {
      return this.isActive;
    },
  },
  methods: {
    async getPlanDetails() {
      this.loading = true;
      try {
        const user = this.getUser;
        if (user.id) {
          const { activePlanID, annual, active } = this.getUser;
          if (active) {
            const { data } = await plansService.getById(activePlanID);
            this.plan = {
              budget: annual ? data.annualFee : data.monthlyFee,
              type: annual ? this.$t("create_campaign_page.plan_type_annual_text") : this.$t("create_campaign_page.plan_type_monthly_text"),
              id: activePlanID,
              name: data.name,
            };
            this.isActive = true;
          }
        } else {
          const { data: dataUser } = await authService.getUserInfo();
          const { activePlanID, annual, active } = dataUser;
          if (active) {
            const { data } = await plansService.getById(activePlanID);
            this.plan = {
              budget: annual ? data.annualFee : data.monthlyFee,
              type: annual ? this.$t("create_campaign_page.plan_type_annual_text") : this.$t("create_campaign_page.plan_type_monthly_text"),
              id: activePlanID,
              name: data.name,
            };
            this.isActive = true;
          }
        }
      } catch (err) {
        const errCode = err.response?.statusText;
        this.$notify({
          group: "foo",
          type: "error",
          title: errCode ? errCode : this.$t("something_wrong"),
        });
      }
      this.loading = false;
    },
    async getPlanDetailsAfterUpdating() {
      this.loading = true;
      try {
        const { data: dataUser } = await authService.getUserInfo();
        const { activePlanID, annual, active } = dataUser;
        if (active) {
          this.isActive = true;
          const { data } = await plansService.getById(activePlanID);
          this.plan = {
            budget: annual ? data.annualFee : data.monthlyFee,
            type: annual ? this.$t("create_campaign_page.plan_type_annual_text") : this.$t("create_campaign_page.plan_type_monthly_text"),
            id: activePlanID,
            name: data.name,
          };
        }
      } catch (err) {
        const errCode = err.response?.statusText;
        this.$notify({
          group: "foo",
          type: "error",
          title: errCode ? errCode : this.$t("something_wrong"),
        });
      }
      this.loading = false;
    },
    openModalToChoosePlan() {
      if (this.paymentMethodId) {
        this.isChangePlan = true;
      } else {
        this.$notify({
          group: "foo",
          type: "error",
          title: "Please, add a payment method before choosing a plan!",
        });
        this.$emit("showWarningText");
      }
    },
  },
};
</script>
<style scoped>
.fld{
  flex-wrap: wrap;
}
@media screen and (max-width: 450px) {

  .button{
    width: 100%;
  }
  span{
    width: 100%;
  }
}
</style>
